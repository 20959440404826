'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.tasks.factory:TasksManager

 # @description

###
angular
  .module 'mundoAdmin.tasks'
  .factory 'TasksManager', [
    'MundoDefaultManager'
    '$state'
    '$timeout'
    '$rootScope'
    (
      MundoDefaultManager
      $state
      $timeout
      $rootScope
    )->
      TasksManagerBase = new MundoDefaultManager()

      TasksManagerBase.setUrl('lpa/tasks')
      TasksManagerBase.setActions(['list', 'print', 'search'])
      TasksManagerBase.setSearchFields(
        [
          'label'
          'taskType.label'
          'incident.externalId'
          'dispatchUnit.label'
          'dispatchUnit.unit.label'
        ]
      )

      TasksManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'Label'
          sort: 'label'
        ,
          key: 'taskType.label'
          title: 'Type'
          sort: 'taskType.label'
        # ,
        #   key: 'incident.externalId'
        #   title: 'Incident'
        #   sort: 'incident.externalId'
        # ,
        #   key: 'dispatchUnit.label'
        #   title: 'Dispatch Unit'
        #   sort: 'dispatchUnit.label'
        # ,
        #   key: 'dispatchUnit.unit.label'
        #   title: 'Unit'
        #   sort: 'dispatchUnit.unit.label'
        ,
          key: 'externallyConfirmed'
          title: 'Externally confirmed'
          sort: 'externallyConfirmed'
          type: 'boolean'
        ,
          key: 'createdAt'
          title: 'datatable.label.created.at'
          sort: 'createdAt'
          type: 'dateTime'
        ,
          key: 'closedAt'
          title: 'datatable.label.closed.at'
          sort: 'closedAt'
          type: 'dateTime'
        # ,
        #   key: 'tenant.label',
        #   title: "datatable.label.tenant"
        #   hideInList: true
         # ,
         #   key: 'closedAt'
         #   title: 'datatable.label.closed.at'
         #   sort: 'closedAt'
         #   type: 'dateTime'
        ]

      TasksManagerBase.getSchemaLinks = ->
        [
          key: 'incident.externalId'
          title: 'Incident'
          sort: 'incident.externalId'
          action: (row) ->
            if row.incident?
              incident = row.incident.externalId
              $state.go('incidents.incidents').then(->
                $timeout(->
                  $rootScope.$broadcast('searchFor',
                    {term: incident, to: 'app.admin.pageTitles.incidents.incidents'})
                ,100)
              )
        ,
          key: 'dispatchUnit.label'
          title: 'Dispatch Unit'
          sort: 'dispatchUnit.label'
          action: (row) ->
            if row.dispatchUnit?
              dispatchUnit = row.dispatchUnit.label
              $state.go('dispatch.units').then(->
                $timeout(->
                  $rootScope.$broadcast('searchFor',
                    {term: dispatchUnit, to: 'app.admin.pageTitles.dispatching.units'})
                ,100)
              )
        ,
          key: 'dispatchUnit.unit.label'
          title: 'Unit'
          sort: 'dispatchUnit.unit.label'
          action: (row) ->
            if row.dispatchUnit? and row.dispatchUnit.unit?
              unit = row.dispatchUnit.unit.label
              $state.go('units.overview').then(->
                $timeout(->
                  $rootScope.$broadcast('searchFor',
                    {term: unit, to: 'app.admin.pageTitles.units.units'})
                ,100)
              )
        ]

      TasksManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/tasks/views/task-detail.tpl.html'

      unselectedIcon1 = 'done'
      unselectedText1 = 'show only confirmed tasks'

      selectedIcon1 = 'check_circle'
      selectedText1 = 'show confirmed and unconfirmed tasks'

      filterOnConfirmed =
        [
          icon: unselectedIcon1
          text: unselectedText1
          action: (ctrl) ->
            if @.icon == selectedIcon1
              @.icon = unselectedIcon1
              @.text = unselectedText1
            else
              @.icon = selectedIcon1
              @.text = selectedText1

            show = TasksManagerBase.filterConfirmed()
            # reload the items in the list
            loadFilterParams = $state.$current.locals['tasks@tasks'].$scope.listCtrl.loadFilterParams
            if show
              loadFilterParams['filter[externallyConfirmed]'] = 'externallyConfirmed,TRUE'
            else
              loadFilterParams['filter[externallyConfirmed]'] = undefined

            $state.$current.locals['tasks@tasks'].$scope.listCtrl.loadFilterParams = loadFilterParams

            $state.$current.locals['tasks@tasks'].$scope.listCtrl.load()
        ]

      TasksManagerBase.filterConfirmed = () =>
        @showConfirmedOnly = !@showConfirmedOnly
        @showConfirmedOnly

      TasksManagerBase.addTableAction(filterOnConfirmed)

      unselectedIcon = 'add_circle_outline'
      unselectedText = 'show active and inactive tasks'

      selectedIcon = 'remove_circle_outline'
      selectedText = 'show only active tasks'

      filterOnClosed =
        [
          icon: unselectedIcon
          text: unselectedText
          action: (ctrl) ->
            if @.icon == selectedIcon
              @.icon = unselectedIcon
              @.text = unselectedText
            else
              @.icon = selectedIcon
              @.text = selectedText

            show = TasksManagerBase.filterClosed()
            # reload the items in the list
            loadFilterParams = $state.$current.locals['tasks@tasks'].$scope.listCtrl.loadFilterParams
            if show
              loadFilterParams['filter[closedAt]'] = undefined
            else
              loadFilterParams['filter[closedAt]'] = 'OR,closedAt,NULL,closedAt,GTE ' + moment.utc().format()

            $state.$current.locals['tasks@tasks'].$scope.listCtrl.loadFilterParams = loadFilterParams

            $state.$current.locals['tasks@tasks'].$scope.listCtrl.load()
        ]

      TasksManagerBase.filterClosed = () =>
        @showClosed = !@showClosed
        @showClosed

      TasksManagerBase.addTableAction(filterOnClosed)

      TasksManagerBase.showConfirmedOnly = false
      TasksManagerBase.showClosed = false

      TasksManagerBase.editPermission = 'manage all MundoMosaLpaDispatchingBundle:Task entities'

      TasksManagerBase
  ]
